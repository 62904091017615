import React, { useState, useEffect } from 'react';
import axios from 'axios';
import User from './User'; // Importa el componente User
import { useNavigate } from 'react-router-dom';
import './user.css'
import NavScrollExample from '../Navbar/Navbar';
import order from '../../assets/img/icono2.jpeg'





const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/users/profile', {
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        });

        setProfileData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProfile();
  }, [token]);

  const handleLogout = () => {
    // Limpiar datos de la sesión en el localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("cart");
    localStorage.removeItem("email");
    localStorage.removeItem("userRole");
    localStorage.removeItem("id");
    // Redirigir al usuario a la página de inicio de sesión
    navigate('/login'); 
  };

  if (loading) {
    
    return <div>  Cargando perfil...</div>;
  }

  if (error) {
    navigate('/login'); // Redirige al login si ocurre un error
    return null; // Retorna null para evitar renderizar contenido innecesario
  }

  if (!profileData) {
    navigate('/login'); // Redirige al login si no hay datos del perfil
    return null;
  }

  return (
    <div>
           <NavScrollExample/>
      <div className='profile-container'>
        <h2>Hola  {profileData.email}!</h2>
        <button onClick={handleLogout}>Logout</button> {/* Agrega el botón de logout */}
      </div>
      <div className='orders-container'>
        <h2>Compras recientes</h2>
        <Orders token={token} userId={profileData.id} orderImage={order} />
      </div>
    </div>
  );
};


const Orders = ({ userId, token ,orderImage}) => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`https://accesoriosvilmix.com.ar.api.accesoriosvilmix.com.ar/api/orders/${userId}/orders`, {
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        });

        setOrders(response.data.orders);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userId, token]);

  if (loading) {
    return <div>Cargando órdenes...</div>;
  }

  if (error) {
    return <div>No tienes ninguna compra </div>;
  }

  return (
<div className="orders-container">
  {orders.map(order => (
    <div key={order.id} className="order-box">
      <div className="order-details">
        <strong>Productos:</strong><br />
        {order.products && JSON.parse(order.products).map(product => (
          <div key={product.id}>
            <strong>{product.nombre} x{product.cantidad}</strong>
          </div>
        ))}

        <strong>Tipo de Entrega:</strong> {order.tipoEntrega}<br />
        <strong>Método de Pago:</strong> {order.metodoPago}<br />
        <strong>Total: $</strong> {order.total}
      
      </div>
      <div className="order-image">
        <img src={orderImage} alt="Imagen de la orden" />
      </div>
    </div>
  ))}
</div>

  );

};



export default Profile;
