import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCartContext } from "../../context/CartContext";
import CartItem from "../Cart/CartItem";
import NavScrollExample from "../Navbar/Navbar";

const Checkout = () => {
    const { cart, totalPrice } = useCartContext();
    const [isPaymentCompleted, setPaymentCompleted] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado para la autenticación
    const navigate = useNavigate();

    useEffect(() => {
        // Verificar si hay un token almacenado en localStorage
        const token = localStorage.getItem("token");
        if (token) {
            setIsAuthenticated(true); // Si hay token, el usuario está autenticado
        } else {
            setIsAuthenticated(false); // Si no hay token, no está autenticado
        }
    }, []);

    const handleCheckout = () => {
        if (!isAuthenticated) {
            // Si no está autenticado, no permitir la compra
            return;
        }

        // Simulando el proceso de pago completado
        setPaymentCompleted(true);

        // Redirigir al usuario a OrderCheckout
        navigate('/CheckoutOrder');
    };

    return (
        <div className="check-container">
            <NavScrollExample />
            <table>
                <thead>
                    <tr>
                        <th>Producto</th>
                        <th>Nombre</th>
                        <th>Precio</th>
                        <th>Cantidad</th>
                        <th>Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    {cart.map(item => (
                        <CartItem key={item.id} item={item} />
                    ))}
                </tbody>
            </table>
            <div className="total-container">
                <h2>Total: ${totalPrice()}</h2>
                {/* Deshabilitar el botón si no está autenticado */}
                <button 
                    onClick={handleCheckout} 
                    disabled={!isAuthenticated}
                    style={{ backgroundColor: isAuthenticated ? 'initial' : 'gray', cursor: isAuthenticated ? 'pointer' : 'not-allowed' }}
                >
                    Terminar Compra
                </button>
                {!isAuthenticated && (
                <p className="auth-warning" style={{ color: 'red', fontWeight: 'bold' }}>
                    Debes iniciar sesión para realizar una compra.
                </p>
            )}
            </div>
        </div>
    )
}

export default Checkout;


